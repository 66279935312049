import {
  CONTACT_PAGE,
  COOKIE_PAGE,
  EXPERT_TALKS_PAGE,
  FOR_NURSES_PAGE,
  FOR_PARTNERS_PAGE,
  IMPRINT_PAGE,
  PRIVACY_POLICY_PAGE,
  LEGAL_NOTICE_PAGE,
  PARTNERS_PAGE,
  TRAINING_PAGE,
  COACHING_PAGE,
  CLIPS_PAGE,
  DASHBOARD_PAGE,
  HOME_PAGE,
  COACHING_MODULE_PAGE,
  FULL_CONTACT_PAGE,
} from './routes';
import { VIDEO_COACHING_MODULE_TRANSLATION } from './video';

export const WINDOW_WIDTH_XXL = 1500;
export const WINDOW_WIDTH_XL = 1200;
export const WINDOW_WIDTH_LG = 991;
export const WINDOW_WIDTH_MD = 768;
export const WINDOW_WIDTH_SM = 576;
export const WINDOW_WIDTH_XM = 420;

export const MY_PROFILE_INITIALS = 'CC';
export const MEETING_TYPE_ZOOM = 'zoom';

export const TRAINING_PAGE_FILTERS = ['indications', 'companies', 'medicines', 'ingredientGroups'];
export const TRAINING_PAGE_DATE_BOOK_TYPE = 'book';
export const TRAINING_PAGE_DATE_RESERVE_TYPE = 'reserve';
export const TRAINING_PAGE_DATE_INTERESTED_TYPE = 'interested';

export const DATE_SCHEDULE_DEFAULT_VALUES = {
  code: '',
  date: '',
};

export const FOOTER_PAGES = [IMPRINT_PAGE, COOKIE_PAGE, LEGAL_NOTICE_PAGE, CONTACT_PAGE, PRIVACY_POLICY_PAGE];
export const FOOTER_LINKS = [
  {
    translation: 'imprint',
    url: IMPRINT_PAGE,
  },
  {
    translation: 'privacyPolicy',
    url: PRIVACY_POLICY_PAGE,
  },
  {
    translation: 'cookiePolicy',
    url: COOKIE_PAGE,
  },
  {
    translation: 'legalNotice',
    url: LEGAL_NOTICE_PAGE,
  },
  {
    translation: 'contact',
    url: FULL_CONTACT_PAGE,
  },
];

export const FOOTER_COACHING_LINKS = [
  { translation: 'coaching', url: COACHING_PAGE },
  { translation: 'training', url: TRAINING_PAGE },
  { translation: 'talks', url: EXPERT_TALKS_PAGE },
  { translation: 'clips', url: CLIPS_PAGE },
];

export const FOOTER_PARTNER_LINKS = [
  { translation: 'cooperations', url: PARTNERS_PAGE },
  { translation: 'sponsoring', url: PARTNERS_PAGE },
];

export const HEADER_LINKS_NOT_AUTHENTICATED = [
  {
    translation: 'about.us',
    external: true,
    url: 'https://www.medtoday.info/',
  },
  {
    translation: 'fortbildungen',
    subLinks: [
      { translation: 'coaching', url: COACHING_PAGE, subtitle: 'coaching.subtitle' },
      { translation: 'training', url: TRAINING_PAGE, subtitle: 'training.subtitle' },
      { translation: 'talks', url: EXPERT_TALKS_PAGE, subtitle: 'talks.subtitle' },
      { translation: 'clips', url: CLIPS_PAGE, subtitle: 'clips.subtitle' },
    ],
  },
  {
    translation: 'partner',
    url: PARTNERS_PAGE,
  },
  // {
  //   translation: 'forNurses',
  //   url: FOR_NURSES_PAGE,
  // },
  // {
  //   translation: 'forPartners',
  //   url: FOR_PARTNERS_PAGE,
  // },
  // {
  //   translation: 'coaching',
  //   url: COACHING_PAGE,
  // },
];

export const HEADER_LINKS_AUTHENTICATED = [
  {
    translation: 'about.us',
    external: true,
    url: 'https://www.medtoday.info/',
  },
  {
    translation: 'fortbildungen',
    subLinks: [
      { translation: 'coaching', url: COACHING_PAGE, subtitle: 'coaching.subtitle' },
      { translation: 'training', url: TRAINING_PAGE, subtitle: 'training.subtitle' },
      { translation: 'talks', url: EXPERT_TALKS_PAGE, subtitle: 'talks.subtitle' },
      { translation: 'clips', url: CLIPS_PAGE, subtitle: 'clips.subtitle' },
    ],
  },
  {
    translation: 'partner',
    url: PARTNERS_PAGE,
  },
  // {
  //   translation: 'dashboard',
  //   url: DASHBOARD_PAGE,
  // },
  // {
  //   translation: 'training',
  //   url: TRAINING_PAGE,
  // },
  // {
  //   translation: 'coaching',
  //   url: COACHING_PAGE,
  // },
  // {
  //   translation: 'expert.talks',
  //   url: EXPERT_TALKS_PAGE,
  // },
  // {
  //   translation: 'partners',
  //   url: PARTNERS_PAGE,
  // },
];

export const MAX_STEP_INDICATOR = 4;

// Status codes
export const NOT_FOUND_CODE = 404;
export const FORBIDDEN_CODE = 403;

export const PARTNERS_OVERVIEW_BREADCRUMBS = [
  { translation: 'partners.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'partners.page.breadcrumbs.partner', url: PARTNERS_PAGE },
];

export const PARTNERS_DETAIL_OVERVIEW_BREADCRUMBS = [
  { translation: 'partners.page.breadcrumbs.homepage', url: HOME_PAGE },
  { translation: 'partners.page.breadcrumbs.partner', url: PARTNERS_PAGE },
  { translation: VIDEO_COACHING_MODULE_TRANSLATION, url: COACHING_MODULE_PAGE },
];
