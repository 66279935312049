import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SET_IS_LOADING } from '../constants/actionTypes';
import useGeneral from './useGeneral';
import { overviewPageApi } from 'api/publicPagesApi';

function useOverviewPage(page) {
  const [overviewPage, setOverviewPage] = useState([]);
  const isRegisterToEvent = useSelector((store) => store.defaultReducer.isRegisterToEvent);
  const { showToasts } = useGeneral();
  const dispatch = useDispatch();

  useEffect(() => {
    getOverviewPage();
  }, [isRegisterToEvent]);

  async function getOverviewPage() {
    dispatch({ type: SET_IS_LOADING, payload: true });

    try {
      const { data } = await overviewPageApi(page);
      setOverviewPage(data);
    } catch (error) {
      showToasts(error);
    } finally {
      dispatch({ type: SET_IS_LOADING, payload: false });
    }
  }

  return { overviewPage };
}

export default useOverviewPage;
