import React from 'react';
import { useTranslation } from 'react-i18next';

import useRegisterToEvent from '../../hooks/useRegisterToEvent';
import RegisterToEvent from 'components/RegisterToEvent';
import ConfirmTerminateBooking from 'components/ConfirmTerminateBooking';
import useTerminateBooking from 'hooks/useTerminateBooking';
import ContentLayout from 'components/layout/ContentLayout';
import useOverviewPage from 'hooks/useOverviewPage';
import PresentationOverview from 'components/common/sections/PresentationOverview';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import Breadcrumbs from './Breadcrumbs';
import { TRAINING_OVERVIEW_BREADCRUMBS } from 'constants/video';
import PerksSection from 'components/common/sections/PerksSection';
import PartnerSliderSection from 'components/common/sections/PartnerSliderSection';
import NewsletterSection from 'components/common/sections/NewsletterSection';
import { Container, Row, Col } from 'react-grid-system';
import VideoCard from 'components/VideoCard';

function TrainingPage() {
  const { t } = useTranslation();
  const { isUserLoggedIn } = useIsAuthenticated();
  const { isRegisterToEvent } = useRegisterToEvent();
  const { terminateBookingData } = useTerminateBooking();
  const { overviewPage } = useOverviewPage('course');
  const {
    title,
    description,
    thumbnail,
    takeAways,
    sponsorings,
    content,
    newsletterTitle,
    newsletterDescription,
  } = overviewPage;

  let mainComponent = (
    <>
      <PresentationOverview
        topComponent={<Breadcrumbs items={TRAINING_OVERVIEW_BREADCRUMBS} />}
        title={title}
        description={description}
        thumbnail={thumbnail}
        hideButton={isUserLoggedIn}
      />
      {terminateBookingData && <ConfirmTerminateBooking isModalOpen={terminateBookingData.isOpen} onClose={() => {}} />}
      {content && content.live && !!content.live.length && (
        <Container className="container-fluid">
          <h2 className="headline2">{t('training.page.live.title')}</h2>
          <p className="highlight" style={{ marginBottom: 72 }}>
            {t('training.page.live.description')}
          </p>
          <Row>
            {content.live
              .filter((item) => item.firstVideo)
              .map((item) => (
                <Col xs={12} md={4} key={item.title} style={{ marginBottom: 36 }}>
                  <VideoCard
                    id={item.firstVideo ? item.firstVideo.id : item.id}
                    title={item.title}
                    generalInformation={item.availability}
                    thumbnail={item.logo}
                    label={item.label}
                    bookingStatus={item.bookingStatus}
                    course={item.firstVideo}
                    courseId={item.id}
                    courseIsLive={1}
                    buttonClass="primary"
                    isSecondary
                    isVertical
                    hasLiveLabel
                    hasFirstVideo
                    showRegisterButton={!isUserLoggedIn}
                    // onButtonClick={() => handleVideoRedirect(item.id, true)}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      )}

      {content && content.onDemand && !!content.onDemand.length && (
        <Container className="container-fluid">
          <h2 className="headline2" style={{ marginTop: 144, marginBottom: 72 }}>
            {t('training.page.ondemand.title')}
          </h2>
          <Row>
            {content.onDemand.map((item) => (
              <Col xs={12} md={4} key={item.title} style={{ marginBottom: 36 }}>
                <VideoCard
                  id={item.firstVideo ? item.firstVideo.id : item.id}
                  title={item.title}
                  generalInformation={item.shortDescription}
                  thumbnail={item.logo}
                  label={item.label}
                  buttonClass="secondary"
                  isSecondary
                  isVertical
                  showRegisterButton={!isUserLoggedIn}
                />
              </Col>
            ))}
          </Row>
        </Container>
      )}

      <PerksSection title={t('coachings.details.exclusive.title')} items={takeAways} />
      <PartnerSliderSection partners={sponsorings} />
      <NewsletterSection title={newsletterTitle} description={newsletterDescription} />
    </>
  );

  return (
    <ContentLayout title={t('training.page.pagetitle')}>
      {isRegisterToEvent ? <RegisterToEvent /> : mainComponent}
    </ContentLayout>
  );
}

export default TrainingPage;
