import React from 'react';
import { useTranslation } from 'react-i18next';
import useCollapse from 'react-collapsed';

import style from 'assets/scss/components/Header.module.scss';
import navImage from 'assets/images/element-navigation.svg';
import useGeneral from 'hooks/useGeneral';
import { convertKeysToCamelCase } from 'helpers/baseHelper';

export const MobileNavItem = ({ translation, subLinks }) => {
  const { t } = useTranslation();
  const {
    headerLinksItem,
    headerLinksCollapse,
    headerLinksContainer,
    headerLinksElement,
    headerLinksPopoverItemFigure,
  } = convertKeysToCamelCase(style);
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  const { handleRedirect } = useGeneral();

  return (
    <span key={translation}>
      <span {...getToggleProps()} className={`${headerLinksContainer} ${headerLinksElement}`}>
        <span className={headerLinksItem}>{t(`header.links.${translation}`)}</span>
        <i className={`icon icon-${isExpanded ? 'up' : 'down'}-open-big margin-left-10`} />
      </span>
      <ul className={headerLinksCollapse} {...getCollapseProps()}>
        {subLinks.map((sublink) => (
          <>
            <li key={sublink.translation} onClick={() => handleRedirect(sublink.url)} className={headerLinksElement}>
              <>
                <img src={navImage} alt="Schulungen" className={headerLinksPopoverItemFigure} />
                {t(`header.links.${sublink.translation}`)}
              </>
            </li>
          </>
        ))}
      </ul>
    </span>
  );
};

export default MobileNavItem;
