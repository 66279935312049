import React from 'react';
import { useTranslation } from 'react-i18next';

import { convertKeysToCamelCase } from '../helpers/baseHelper';
import style from '../assets/scss/components/ProfileDropdown.module.scss';
import useProfileDropdown from 'hooks/useProfileDropdown';
import { PROFILE_ITEM_TYPES } from 'constants/profile';
import ProfileDropdownCloser from './ProfileDropdownCloser';

function ProfileModal() {
  const { t } = useTranslation();
  const { wrapper, wrapperContent, wrapperFooter, avatar, avatarImage, emailContent } = convertKeysToCamelCase(style);
  const { user, initials, isProfileDropdownOpen, handleRedirect, handleLogout } = useProfileDropdown();
  const { firstName, lastName, email, avatar: headerAvatar } = user;

  if (!isProfileDropdownOpen) {
    return null;
  }

  return (
    <ProfileDropdownCloser>
      <div className={wrapper}>
        <div className={avatar}>
          <div className={avatarImage}>
            {!headerAvatar && <span>{initials}</span>}
            {headerAvatar && <img src={headerAvatar} alt="avatar" />}
          </div>
          <div>
            <p>{`${firstName} ${lastName}`}</p>
            <p className={emailContent}>{email}</p>
          </div>
        </div>
        <div className={wrapperContent}>
          <p onClick={() => handleRedirect(PROFILE_ITEM_TYPES.myCourse)}>{t('profile.course')}</p>
          <p onClick={() => handleRedirect(PROFILE_ITEM_TYPES.myProfile)}>{t('dashboard.welcome.card.profile.link')}</p>
        </div>
        <div className={wrapperFooter}>
          <p onClick={() => handleLogout()}>{t('profile.logout')}</p>
        </div>
      </div>
    </ProfileDropdownCloser>
  );
}

export default ProfileModal;
