import React, { useMemo } from 'react';

import usePublicPages from '../../hooks/usePublicPages';
import {
  COACHING_PAGE,
  CLIPS_PAGE,
  EXPERT_TALKS_PAGE,
  HOME_PAGE_ENDPOINT,
  TRAINING_PAGE,
} from '../../constants/routes';
import ContentLayout from 'components/layout/ContentLayout';
import HeroSection from './homepage/HeroSection';
import HomeVideoSection from './homepage/HomeVideoSection';
import HomeDetailSection from './homepage/HomeDetailSection';
import { useTranslation } from 'react-i18next';
import PerksSection from '../common/sections/PerksSection';
import HomeTeamSection from './homepage/HomeTeamSection';
import forPatientsIcon from 'assets/images/homepage/perks1.svg';
import renownedExpertsIcon from 'assets/images/homepage/perks2.svg';
import servicesIcon from 'assets/images/homepage/perks3.svg';
import tempoIcon from 'assets/images/homepage/perks4.svg';
import NewsletterSection from '../common/sections/NewsletterSection';
import PartnerSliderSection from '../common/sections/PartnerSliderSection';

function HomePage() {
  const { t } = useTranslation();
  const {
    title,
    description,
    squareImage,
    videoTitle,
    videoDescription,
    videoEmbedCode,
    coachingsTitle,
    coachingsDescription,
    coachingsImage,
    klipsTitle,
    klipsDescription,
    klipsImage,
    coursesTitle,
    coursesDescription,
    coursesImage,
    expertTalksTitle,
    expertTalksDescription,
    expertTalksImage,
    forPatients,
    forPatientsTitle,
    renownedExperts,
    renownedExpertsTitle,
    services,
    servicesTitle,
    tempo,
    tempoTitle,
    teamMembers,
    newsletterTitle,
    newsletterDescription,
    sponsorings,
  } = usePublicPages(HOME_PAGE_ENDPOINT);

  const perks = useMemo(() => {
    return [
      { title: forPatientsTitle, description: forPatients, image: forPatientsIcon },
      { title: renownedExpertsTitle, description: renownedExperts, image: renownedExpertsIcon },
      { title: servicesTitle, description: services, image: servicesIcon },
      { title: tempoTitle, description: tempo, image: tempoIcon },
    ];
  }, [forPatients, renownedExperts, services, tempo]);

  return (
    <ContentLayout title={t('home.page.title')}>
      <HeroSection title={title} description={description} image={squareImage} />
      <HomeVideoSection title={videoTitle} description={videoDescription} video={videoEmbedCode} />
      <HomeDetailSection
        title={coachingsTitle}
        description={coachingsDescription}
        image={coachingsImage}
        buttonText={t('general.buttons.toCoachings')}
        buttonUrl={COACHING_PAGE}
      />
      <HomeDetailSection
        title={coursesTitle}
        description={coursesDescription}
        image={coursesImage}
        buttonText={t('general.buttons.toTrainings')}
        buttonUrl={TRAINING_PAGE}
        isInversed
      />
      <HomeDetailSection
        title={expertTalksTitle}
        description={expertTalksDescription}
        image={expertTalksImage}
        buttonText={t('general.buttons.toTalks')}
        buttonUrl={EXPERT_TALKS_PAGE}
      />
      <HomeDetailSection
        title={klipsTitle}
        description={klipsDescription}
        image={klipsImage}
        buttonText={t('general.buttons.toClips')}
        buttonUrl={CLIPS_PAGE}
        isInversed
      />
      <PerksSection title={t('home.perks.title')} items={perks} />
      <PartnerSliderSection partners={sponsorings} />
      <HomeTeamSection teamMembers={teamMembers} />
      <NewsletterSection title={newsletterTitle} description={newsletterDescription} />
    </ContentLayout>
  );
}

export default HomePage;
