import axios from '../api/axiosConfig';
import { EXTRA_ENDPOINT, NEWSLETTER_ENDPOINT, OVERVIEW_PAGES } from '../constants/routes';

export function publicPageApi(endpoint) {
  return axios().get(endpoint);
}

export function getPagesApi(page) {
  const path = `${EXTRA_ENDPOINT}?page=${page}`;

  return axios().get(path);
}

export function newsletterApi(data) {
  return axios().post(NEWSLETTER_ENDPOINT, data);
}

export function overviewPageApi(type) {
  return axios().get(OVERVIEW_PAGES, { params: { type: type } });
}
