import React from 'react';
import { convertKeysToCamelCase } from 'helpers/baseHelper';
import style from 'assets/scss/components/common/sections/PerksSection.module.scss';
import { Container, Row, Col } from 'react-grid-system';
import bubbleImage from 'assets/images/bubbles.svg';

const PerksSection = ({ title, items }) => {
  const { perksSection, perksSectionItem } = convertKeysToCamelCase(style);

  return (
    <section className={perksSection}>
      <Container className="container-fluid">
        <h2 className="headline2 text-center">{title}</h2>
        <Row>
          {items &&
            items.map((item) => (
              <Col xs={12} md={6}>
                <div className={perksSectionItem}>
                  <img src={item.image || bubbleImage} alt={item.title} />
                  <div>
                    <h3 className="highlight">{item.title}</h3>
                    <p className="copy">{item.description}</p>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </section>
  );
};

export default PerksSection;
