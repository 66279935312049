import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { ToastContainer, Slide } from 'react-toastify';

import rootReducer from '../redux/reducers/index';
import Footer from './Footer';
import Header from './layout/Header';
import Routes from './Routes';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';
import '../i18n';
import CookieConsent from './CookieConsent';
import ScrollToTop from './helpers/ScrollToTop';

const store = createStore(
  rootReducer,
  //add this to enable redux devtools
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

function App() {
  return (
    <Provider store={store}>
      <div className="app-wrapper" id="app-wrapper-id">
        <Router>
          <ScrollToTop />
          <Header />

          <Routes />

          <Footer />

          <CookieConsent />
        </Router>
      </div>

      <ToastContainer hideProgressBar={true} transition={Slide} autoClose={3000} />
    </Provider>
  );
}

export default App;
